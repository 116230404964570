


















































import { defineComponent } from "@vue/composition-api";
import accountStateRef from "@/apps/accounts/modules/store";
import router from "@/router";

export default defineComponent({
  name: "FiturSection",
  setup() {
    const buatSetoranUrl = require("@/apps/nasabah/assets/img/setoran.png");
    const buatPinjamanUrl = require("@/apps/nasabah/assets/img/pinjaman.png");
    const penarikanUrl = require("@/apps/nasabah/assets/img/penarikan.png");

    const setoranMenus: Array<Record<string, string>> = [
      { id: "0", label: "Buat Setoran", routeName: "setoran-mandiri" },
      { id: "1", label: "Buat Setoran Pelunasan", routeName: "setoran-pelunasan" },
      { id: "2", label: "Daftar Setoran", routeName: "setoran-mandiri-list" },
    ];
    const navigateSetoran = (routeName: string) => {
      router.push({ name: routeName });
    };
    return {
      // Data
      accountStateRef,
      buatSetoranUrl,
      buatPinjamanUrl,
      penarikanUrl,
      setoranMenus,

      // Method
      navigateSetoran,
    };
  },
});
